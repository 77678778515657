import React from 'react'
import Videoprovider from "../Videoprovider"
function Video() {
    return (
        <>
        <Videoprovider/>
        </>
    )
}

export default Video
